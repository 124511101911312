<template>
  <div class="detalle-dialog-cuadre">
    <v-dialog v-model="dialog" width="900" v-if="content">
      <v-card>
        <v-card-title>{{ content.title }}</v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :loading="content.loading"
            :headers="content.headers"
            :items="content.body"
            hide-default-footer
            :items-per-page="content.paginate.perPage"
            :page.sync="content.paginate.page"
          >
            <template v-slot:top>
              <v-row dense>
                <v-col cols="12" md="4" sm="6">
                  <v-pagination
                    dense
                    v-model="content.paginate.page"
                    :length="lengthPagination"
                    :total-visible="6"
                    @input="changePage"
                  ></v-pagination>
                </v-col>
              </v-row>
            </template>
            <template v-slot:body.append v-if="totalizacion">
              <tr>
                <th :colspan="content.footer.colspan">{{content.footer.text}}</th>
                <th v-for="(item, index) in content.footer.totalizacion" :key="index">
                  {{totalizacion[item] | currency}}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "detalleDialog",
  props: ["content"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    dialogActivator() {
      this.dialog = !this.dialog;
    },
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
  computed: {
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.content.paginate.total / this.content.paginate.perPage;
      if (!is_decimal(number)) number = parseInt(number) + 1;
      return number;
    },
    totalizacion() {
      if (this.content.footer) {
        let calculate_reduce = (array, value) => {
          return array.reduce((index, item) => {
            return index + parseFloat(item[value]);
          }, 0);
        };

        const obj = this.content.footer.totalizacion.reduce((arr, val) => {
          arr[val] = 0;
          return arr;
        }, {});

        this.content.footer.totalizacion.forEach((item) => {
          obj[item] = calculate_reduce(this.content.body, item);
        });

        return obj;
      }
      return false;
    },
  },
};
</script>
<template>
  <div class="detalle-caja-completo">
    <v-btn color="black" dark @click="generarDetalle()">
      <v-icon>mdi-download</v-icon>
      Generar detalle
    </v-btn>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  name: "detalleCaja",
  props: {
    date: Object,
    list: {
      type: Array,
      default: [],
    },
  },
  methods: {
    generarDetalle() {
      const date = this.date;
      let profesionales = this.get_list.map((p) => {
        return p.id;
      });
      profesionales = JSON.stringify(profesionales);
      const body = {
        route: "/detalle_cuadre_caja_completo",
        params: {
          profesionales,
          date,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          const name = `detalle-caja-completo`;
          let _body = [
            {
              Columna_A: "Fecha inicial",
              Columna_B: moment(this.date.inicio).format("ll"),
              Columna_C: "",
              Columna_D: "",
              Columna_E: "",
              Columna_F: "",
            },
            {
              Columna_A: "Fecha final",
              Columna_B: moment(this.date.fin).format("ll"),
              Columna_C: "",
              Columna_D: "",
              Columna_E: "",
              Columna_F: "",
            },
          ];
          const divider = {
            Columna_A: "",
            Columna_B: "",
            Columna_C: "",
            Columna_D: "",
            Columna_E: "",
            Columna_F: "",
          };
          let lista = response.data.data;
          lista.forEach((item) => {
            _body.push(divider);
            const _header_prof = {
              Columna_A: "Profesional",
              Columna_B: "",
              Columna_C: "",
              Columna_D: "Cedula",
              Columna_E: "",
              Columna_F: "",
            };
            _body.push(_header_prof);
            const header_prof = {
              Columna_A: item.profesional,
              Columna_B: "",
              Columna_C: "",
              Columna_D: item.cedula,
              Columna_E: "",
              Columna_F: "",
            };
            _body.push(header_prof);
            item.servicios.forEach((servicio) => {
              let _header_servicio = {
                Columna_A: "Servicio",
                Columna_B: "",
                Columna_C: "",
                Columna_D: "",
                Columna_E: "Cantidad",
                Columna_F: "",
              };
              _body.push(_header_servicio);
              let header_servicio = {
                Columna_A: servicio.servicio,
                Columna_B: "",
                Columna_C: "",
                Columna_D: "",
                Columna_E: servicio.cantidad,
                Columna_F: "",
              };
              _body.push(header_servicio);
              const header_detalle = {
                Columna_A: "Factura",
                Columna_B: "Cliente",
                Columna_C: "Porcentaje",
                Columna_D: "Ganancia",
                Columna_E: "Productos de consumo",
                Columna_F: "Total servicio",
              };
              _body.push(header_detalle);
              servicio.detalle.forEach((detalle) => {
                let data_detalle = {
                  Columna_A: this.$options.filters.folio(detalle.folio),
                  Columna_B: detalle.cliente,
                  Columna_C: detalle.porcentaje,
                  Columna_D: detalle.total,
                  Columna_E: detalle.consumos,
                  Columna_F: detalle.valor,
                };
                _body.push(data_detalle);
              });
              //   _body.push(divider);
            });
          });
          let excel = XLSX.utils.json_to_sheet(_body);
          const workbook = XLSX.utils.book_new();
          const filename = name;
          XLSX.utils.book_append_sheet(workbook, excel, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        }
      });
    },
  },
  computed: {
    get_list() {
      return this.list.filter((p) => {
        return p.id != null && p.ventas > 0;
      });
    },
  },
};
</script>
